.card {
    $px: 30px;

    color: $font-color;
    margin-bottom: 30px;
    background-color: $card-color-bg;
    @include get-radius($card-radius);
    @include get-shadow();

    .cover {
        width: 100%;
        height: 200px;
        background-size: cover;
        @include get-positioned-radius(inherit, top-left);
        @include get-positioned-radius(inherit, top-right);

        img {
            width: 100%;
            height: 150px;
            object-fit: cover;
        }
    }

    .content {
        color: $font-color;
        padding: 30px;
        &.heightened {
            height: 100% !important;
        }
        &.no.padding {
            padding: 0 !important;
        }
        &.shaded {
            .scrollable {
                overflow-y: auto;
                &.with-gradient {
                    @include scrollable-gradient($card-color-bg-shade 30%, rgba(91, 94, 95, 0));
                }
            }
        }
        &.news {
            .card {
                $shadow: rgba(22, 44, 83, 0.05);
                $shadow-prop: 0 0 4px $shadow, 0 0 8px $shadow, 0 0 16px $shadow, 0 0 32px $shadow;
                $background: $blue;

                @include get-radius($std-radius);
                @include get-shadow($shadow-prop);

                @include xs() {
                    @include get-radius(0);
                    @include get-shadow(none);
                }

                > .content {
                    padding: 20px;
                    @include xs() {
                        $gutter: 5px;
                        padding: 0 0 (20px + $gutter);
                        margin-bottom: -$gutter;
                        border-bottom: 1px solid $border-color;
                    }

                    .ui.header {
                        color: darken($blue, 5%);
                        display: block;
                        font-size: 16px;
                        padding-right: 20px;
                        margin-bottom: 10px;
                    }

                    p {
                        text-align: justify;
                        line-height: 20px;
                    }
                }

                .date {
                    top: 0;
                    right: 0;

                    color: $white;
                    width: 56px;
                    height: 56px;
                    z-index: 2;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    background-color: $background;
                    @include get-radius(64%);
                    @include xs() {
                        display: none;
                    }

                    .day {
                        top: 2px;
                        display: block;
                        position: relative;
                        font-size: 18px;
                        text-align: center;
                        font-weight: 500;
                    }
                    .month {
                        top: 0;
                        position: relative;
                        text-align: center;
                        font-size: 12px;
                        font-weight: 500;
                    }
                }

                &:hover {
                    $shadow: rgba(24, 79, 175, 0.09);
                    $shadow-prop: 0 0 4px $shadow, 0 0 8px $shadow, 0 0 16px $shadow, 0 0 32px $shadow,
                        0 0 64px $shadow;
                    @include get-shadow($shadow-prop);
                    @include get-transition();

                    .date {
                        animation: pulseDot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
                        box-sizing: border-box;
                        align-items: center;
                        border-radius: 100%;
                        justify-content: center;
                        background-color: $background;

                        &:before {
                            top: -25%;
                            left: -25%;
                            width: 150%;
                            height: 150%;
                            content: "";
                            position: absolute;
                            animation: pulseRing 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
                            border-radius: 100%;
                            background-color: $background;
                        }

                        @keyframes pulseRing {
                            0% {
                                transform: scale(0.5);
                            }
                            80%,
                            100% {
                                opacity: 0;
                            }
                        }

                        @keyframes pulseDot {
                            0%,
                            100% {
                                transform: scale(1);
                            }
                            50% {
                                transform: scale(1.1);
                            }
                        }
                    }
                }
            }
        }

        p {
            font-size: 14px;
            line-height: 24px;
            margin-bottom: $px;
            &:last-of-type {
                margin-bottom: 0;

                img[src*="portrait"] {
                    margin-bottom: 0 !important;
                }
            }
        }

        img {
            width: 50%;
            margin: auto;
            display: flex;
            align-self: center;
            max-width: 100%;
            @include get-radius(3px);
            @include xs() {
                width: 100%;
            }

            &[src*="logo"] {
                width: 150px;
                display: inline-block;
            }

            &[src*="portrait"] {
                width: 400px;
                margin-top: 50px;
                margin-bottom: -$px;
            }

            &[src*="full-width"] {
                width: 100%;
            }
        }

        .toc {
            padding: 20px;
            background-color: lighten($base, 2%);
            @include get-radius($std-radius);

            ul {
                margin: 0;
                padding: 0;
                font-size: 14px;
                list-style: none;
                li {
                    margin: 10px 0;
                    display: table;

                    &:first-of-type {
                        margin-top: 0;
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    &:hover {
                        color: $font-light;
                    }

                    &.active {
                        color: $blue;
                        font-weight: bold;
                        padding-left: 5px;
                        &:hover {
                            color: lighten($blue, 10%);
                        }
                    }

                    .icon {
                        width: 15px;
                        color: $font-light;
                        display: table-cell;
                        font-size: 10px;
                        vertical-align: top;
                    }

                    .title {
                        color: inherit;
                        cursor: pointer;
                        display: table-cell;
                        font-weight: inherit;
                        vertical-align: top;
                    }
                }
            }
        }

        .centralized {
            width: 100%;
            .youtube {
                width: 100% !important;
                height: 100% !important;
                @include get-radius($std-radius);

                @include xs() {
                    width: 100% !important;
                    height: 200px !important;
                }
            }
        }

        > .ui.grid {
            > .column {
                @include xs() {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }

                > .ui.header {
                    &:first-child {
                        margin-bottom: 20px;
                    }
                }
            }
        }

        > .ui.header {
            margin-bottom: 20px;
        }

        .ui.header {
            &.portrait-caption {
                display: block !important;
                text-align: center !important;
                padding-top: 8px;
                margin-bottom: $px !important;
            }
        }

        @include xs() {
            padding: 25px;
        }
    }

    > .button.container {
        padding: 20px 25px;
        border-top: 1px solid darken($card-color-bg-shade, 3%);
        &.darken {
            background-color: $card-color-bg-shade;
            &:last-child {
                @include get-positioned-radius($card-radius, bottom-left);
                @include get-positioned-radius($card-radius, bottom-right);
            }
        }
    }

    /**
     * Shaded card styling. For this one, we need to carefully style grids, and shade
     * the content element with shaded class.
     * 
     * @since   1.0
     **/
    &.shaded {
        $shaded-card-radius: (
            top: (
                top-left,
                top-right,
            ),
            left: (
                top-left,
                bottom-left,
            ),
            right: (
                top-right,
                bottom-right,
            ),
            bottom: (
                bottom-left,
                bottom-right,
            ),
            top-left: (
                top-left,
            ),
            top-right: (
                top-right,
            ),
            bottom-left: (
                bottom-left,
            ),
            bottom-right: (
                bottom-right,
            ),
        );

        %shade {
            background-color: $card-color-bg-shade;

            .om.shimmer {
                $shimmer-bg: darken($card-color-bg-shade, 3%);
                @include shimmer($shimmer-bg, darken($shimmer-bg, 3%));
            }

            @each $key, $value in $shaded-card-radius {
                &.#{$key} {
                    @each $position in $value {
                        @include get-positioned-radius($card-radius, $position);
                    }
                }
            }

            @include resize((xs, sm-only)) {
                $radius: $card-radius;
                &.top,
                &.left,
                &.top-left,
                &.bottom-left {
                    @include get-radius(0 !important);
                    @include get-positioned-radius($radius !important, top-left);
                    @include get-positioned-radius($radius !important, top-right);
                }

                &.bottom,
                &.right,
                &.top-right,
                &.bottom-right {
                    @include get-radius(0 !important);
                    @include get-positioned-radius($radius !important, bottom-left);
                    @include get-positioned-radius($radius !important, bottom-right);
                    &.hasbuttons {
                        @include get-positioned-radius(0 !important, bottom-left);
                        @include get-positioned-radius(0 !important, bottom-right);
                    }
                }
            }
        }

        > .content.shaded {
            @extend %shade;
        }

        .ui.grid {
            margin: 0;

            .column {
                padding: 0;
                @include resize((xs, sm-only)) {
                    width: 100% !important;
                }

                > .content.shaded {
                    @extend %shade;

                    section,
                    .section {
                        margin-bottom: 50px;
                        &:last-of-type {
                            margin-bottom: 0;
                        }

                        &.bordered {
                            margin-bottom: 25px;
                            padding-bottom: 25px;
                            border-bottom: 1px solid lighten($border-color, 5%);
                            &:last-of-type {
                                margin-bottom: 0;
                                border-bottom: none;
                                padding-bottom: 0;
                            }
                        }

                        &.highlighted {
                            padding: 20px;
                            background-color: $field-bg;
                            @include get-radius($std-radius);
                        }
                    }

                    .tabs {
                        padding: 0;
                        list-style: none;

                        &.pointer {
                            .item {
                                &.active {
                                    &::after {
                                        content: " ";
                                        position: absolute;
                                        top: 50%; /* At the top of the tooltip */
                                        right: -20px;
                                        border-width: 8px;
                                        border-style: solid;
                                        border-color: transparent $white transparent transparent;
                                        @include css-transform(translateY(-50%));
                                    }
                                }
                            }
                        }

                        .item {
                            $border-color: lighten($border-color, 5%);

                            cursor: pointer;
                            // margin: 0 -10px;
                            padding: 20px 0;
                            display: block;
                            position: relative;
                            list-style: none;
                            border-top: 1px solid $border-color;

                            &:hover,
                            &.active {
                                > * {
                                    color: $font-color;
                                    @include get-transition();
                                    > * {
                                        color: inherit;
                                    }
                                }
                            }

                            &.active {
                                cursor: default;
                                padding: 20px 10px;
                                border-top-color: transparent;
                                background-color: $border-color;
                                @include get-radius(5px);

                                & + .item {
                                    border-top-color: transparent;
                                }
                            }

                            &:first-of-type {
                                border-top: none;
                            }

                            > * {
                                color: lighten($font-color, 5%);
                                display: block;
                                padding: 0 10px;
                            }

                            > .ui.header {
                                margin: 0;
                                .sub.header {
                                    color: $font-light;
                                }
                            }

                            > .action {
                                top: 17px;
                                right: 13px;
                                cursor: pointer;
                                padding: 3px;
                                display: block;
                                position: absolute;
                                font-size: 14px;
                                background: inherit;
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * Custom styling for the card component located down here.
     * 
     * @since   1.0
     **/
    .flexed.tabs {
        .ui {
            &.menu {
                .item {
                    flex: 1;
                    display: flex;
                    font-weight: 600 !important;
                    justify-content: center;
                }
            }
        }
    }

    .ui {
        &.form {
            @extend %form-on-white;
        }

        &.secondary.menu {
            .item {
                color: $font-light;
                padding: 15px 20px;
                font-weight: 600;
                font-family: $font-family;
                background-color: $card-color-tabs;
                @include get-radius(25px);

                &:hover {
                    color: $font-color;
                    background-color: transparent;
                    @include get-transition();
                }

                &.active {
                    color: $font-color;
                    background-color: $card-color-tabs--active;
                }
            }

            &.pointing {
                border-color: $border-color;

                .item {
                    color: $font-light;
                    border-color: inherit;
                    background-color: transparent;
                    @include get-radius(0);

                    &:hover,
                    &:active {
                        color: darken($font-light, 10%);
                        border-color: transparent;
                        background-color: transparent !important;
                    }

                    &.active {
                        color: $font-color;
                        border-color: $font-color;
                    }
                }
            }
        }

        &.segment.tab {
            color: $font-color;
            border: none;
            padding: 0;
            font-size: $font-size;
            font-family: $font-family;
            @include get-shadow(none);
        }
    }

    @each $name, $color in $colors {
        &.#{$name} {
            color: $color !important;
            .ui.header {
                color: inherit;
                .sub.header {
                    color: inherit;
                }
            }
        }
    }

    /**
     * Specific styles for Naturalife
     * 
     * @since   1.0
     **/
    .upload-area {
        width: 100%;
        border: 2px dashed $border-color;
        padding: 30px;
        display: flex;
        min-height: 50px;
        align-items: center;
        justify-content: center;
        @include get-radius(5px);

        div,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            text-align: center;
        }
    }

    &.dark {
        background-color: $dark;
        .content {
            color: $white;
            .icon {
                color: inherit;
            }
            .ui.header {
                color: inherit;
            }
        }
    }

    &.product {
        width: 100%;
        display: table;
        position: relative;
        @extend %self-clear;

        .cover {
            width: 230px;
            height: 230px;
            padding: 0;
            display: table-cell;
            position: relative;
            text-align: center;
            background-color: $card-color-bg-shade;
            @include get-positioned-radius(inherit, top-left);
            @include get-positioned-radius(inherit, bottom-left);

            img {
                top: 0;
                width: auto;
                height: 100%;
                bottom: 0;
                position: absolute;
                object-fit: cover;
                background-size: cover;
                background-position: center center;
                @include get-positioned-radius(inherit, top-left);
                @include get-positioned-radius(inherit, bottom-left);
            }

            svg {
                top: 50%;
                left: 0;
                right: 0;
                margin: auto;
                width: 150px;
                height: auto;
                position: absolute;
                @include css-transform(translateY(-50%));
            }
        }

        .content {
            top: 0;
            display: table-cell;
            padding: 25px !important;
            position: relative;
        }

        .name,
        .price,
        .description {
            margin-bottom: 20px !important;
        }

        .description {
            color: $font-light;
            text-align: justify;
            text-align-last: left;
        }
    }

    &.stats {
        .content {
            padding: 20px !important;
        }

        .label {
            color: lighten($font-color, 10%);
            display: block;
            font-size: $font-size - 2px;
            font-weight: 700;
            text-transform: uppercase;
        }
        .value {
            display: block;
            font-size: 36px;
            line-height: 36px;
        }

        @each $name, $color in $colors {
            &.#{$name} {
                .label {
                    color: lighten($color, 15%);
                }
                .value {
                    color: $color;
                }

                .more {
                    color: $white;
                    background-color: $color;
                    &:hover {
                        background-color: lighten($color, 3%);
                    }
                    &:active {
                        background-color: $color;
                    }
                    .chevron-icon {
                        background-color: lighten($color, 10%);
                        i,
                        svg {
                            color: darken($color, 20%);
                        }
                    }
                }
            }
        }

        &.horizontal {
            margin: 20px 0;
            &:last-of-type {
                margin-bottom: 0;
            }
            &:first-of-type {
                margin-top: 0;
            }

            .content {
                display: flex;
                align-items: center;

                .icon {
                    color: inherit;
                    width: 84px;
                    height: 84px;
                    display: flex;
                    font-size: 40px;
                    align-items: center;
                    justify-content: center;

                    @each $name, $color in $colors {
                        &.#{$name} {
                            color: $color;
                        }
                    }
                }

                .stat {
                    padding-left: 10px;
                    // background-color: goldenrod;
                }

                .ui.header {
                    color: inherit;
                    margin: 0;
                }
            }
        }
    }

    .more {
        $color: $font-color;

        color: $white;
        cursor: pointer;
        display: block;
        padding: 13px 18px;
        position: relative;
        font-size: 12px;
        font-weight: bold;
        background-color: $color;
        @include get-positioned-radius($card-radius, bottom-left);
        @include get-positioned-radius($card-radius, bottom-right);

        &:hover {
            background-color: lighten($color, 3%);
            @include get-transition();
            .icon {
                background-color: $color;
            }
        }

        &:active {
            background-color: $color;
            .icon {
                background-color: darken($color, 5%);
            }
        }

        .chevron-icon {
            $size: 20px;
            top: 50%;
            right: 11px;
            width: $size;
            height: $size;
            display: flex;
            position: absolute;
            text-align: center;
            align-items: center;
            justify-content: center;
            background-color: lighten($color, 10%);
            @include get-radius($size/2);
            @include css-transform(translateY(-50%));

            i,
            svg {
                color: darken($font-color, 20%);
                font-size: 10px;
            }
        }
    }
}
