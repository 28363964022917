.om.gallery {
    &.albums {
        .item {
            .box {
                $height: 250px;
                padding: 5px;
                max-height: $height;

                img {
                    height: $height - 10px;
                    max-height: $height - 10px;
                }
            }

            > .title {
                margin-top: 25px;
            }
        }
    }

    .item {
        .title {
            width: 100%;
            text-align: center;

            .ui.header {
                display: block;
                text-align: inherit !important;
                font-weight: 700 !important;
            }
        }

        .box {
            $height: 200px;

            cursor: pointer;
            position: relative;
            object-fit: cover;
            min-height: 50px;
            max-height: $height;
            background-color: $white;
            @include get-shadow();
            @include get-radius($std-radius);

            &.hover {
                img {
                    &:hover {
                        @include css-transform(scale(1.3));
                        @include get-transition();
                    }
                }
            }

            img {
                width: 100%;
                height: $height - 10px;
                max-height: $height - 10px;
                object-fit: cover;
                object-position: center;
                @include get-radius($std-radius - 1px);
            }

            .icon {
                top: 15px;
                right: 15px;
                color: $white;
                z-index: 2;
                position: absolute;
                font-size: 18px;
                @include get-shadow();
            }
        }

        .boxes {
            position: relative;

            > .box {
                &:nth-child(1),
                &:nth-child(2) {
                    left: 0;
                    right: 0;
                    margin-left: auto;
                    margin-right: auto;

                    width: 95%;
                    height: 30px;
                    display: inline-block;
                    position: absolute;
                }

                &:nth-child(1) {
                    width: 90%;
                    bottom: -10px;
                    // @include css-transform(rotate(-6deg));
                }

                &:nth-child(2) {
                    bottom: -5px;
                    // @include css-transform(rotate(-2deg));
                }
            }

            &.tilted {
                > .box {
                    &:nth-child(1) {
                        width: 90%;
                        bottom: -5px;
                        @include css-transform(rotate(-6deg));
                    }

                    &:nth-child(2) {
                        bottom: -5px;
                        @include css-transform(rotate(-2deg));
                    }
                }
            }
        }
    }
}
