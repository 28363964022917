$padding-x: 15%;
$padding-y: 20px;
$font-size: 14px;
$std-radius: 5px;
$card-radius: 18px;
$sidebar-width: 280px;
$topbar-height: 65px;
$dropdown-width: 210px;
$mobile-padding-h: 20px;
$topbar-padding-h: $padding-x;
$topbar-padding-v: $padding-y;
$content-padding-h: 15%;
$content-padding-v: 30px;

$logo-width: 154px;
$logo-height: 107px;

// Devices Widths
$ipad-v: 768px;
$ipad-h: 1024px;

// Breakpoints
/* prettier-ignore */
$xs: ( max:  767px );
$sm: (
    min: 768px
);
$md: (
    min: 992px
);
$lg: (
    min: 1200px
);
$sm-only: (
    min: map-get($sm, min),
    max: map-get($md, min) - 1
);
$md-only: (
    min: map-get($md, min),
    max: map-get($lg, min) - 1
);

$major-collapse: (xs, sm-only, md-only);
$breakpoints: (
    xs: $xs,
    sm: $sm,
    md: $md,
    lg: $lg,
    sm-only: $sm-only,
    md-only: $md-only
);

$heading-sizes: (
    h1: (
        font-size: 30px,
        sub-font-size: 22px
    ),
    h2: (
        font-size: 24px,
        sub-font-size: 18px
    ),
    h3: (
        font-size: 20px,
        sub-font-size: 16px
    ),
    h4: (
        font-size: 18px,
        sub-font-size: 14px
    ),
    h5: (
        font-size: 16px,
        sub-font-size: 14px
    ),
    h6: (
        font-size: 14px,
        sub-font-size: 14px
    )
);

$input-circular-sizes: (
    tiny: (
        padding: 8px 14px,
        font-size: 10px
    ),
    small: (
        padding: 10px 16px,
        font-size: 12px
    ),
    medium: (
        padding: 12px 18px,
        font-size: 14px
    ),
    large: (
        padding: 14px 20px,
        font-size: 16px
    ),
    big: (
        padding: 16px 22px,
        font-size: 18px
    ),
    huge: (
        padding: 18px 24px,
        font-size: 20px
    ),
    massive: (
        padding: 20px 26px,
        font-size: 22px
    )
);

$input-sizes: (
    tiny: (
        padding: 8px,
        font-size: 10px
    ),
    small: (
        padding: 10px,
        font-size: 12px
    ),
    medium: (
        padding: 12px,
        font-size: 14px
    ),
    large: (
        padding: 14px,
        font-size: 16px
    ),
    big: (
        padding: 16px,
        font-size: 18px
    ),
    huge: (
        padding: 18px,
        font-size: 20px
    ),
    massive: (
        padding: 20px,
        font-size: 22px
    )
);
