.aibim.topbar {
    display: flex;
    position: relative;
    background-color: $white;

    @include resize($major-collapse) {
        width: 100%;
        height: auto;
        padding: $topbar-padding-v - 5px $mobile-padding-h;

        .om.logo {
            $newWidth: 80px;
            width: $newWidth;
            height: aspectHeight($logo-width, $logo-height, $newWidth);
        }

        .left {
            .tagline {
                font-size: 12px !important;
                line-height: 18px !important;
            }
        }
    }

    @include lg() {
        height: 150px;
        padding: $topbar-padding-v $topbar-padding-h;
    }

    .left,
    .right {
        width: 50%;
        float: left;
        display: flex;
        position: relative;
        align-items: center;
    }

    .left {
        .tagline {
            display: inline-block;
            font-size: 16px;
            font-style: italic;
            align-self: flex-end;
            font-family: "Merriweather", serif;
            line-height: 24px;
            padding-left: 20px;

            @include xs() {
                display: none;
            }
        }
    }

    .right {
        justify-content: flex-end;

        ul.social {
            text-align: right;
            @include resize($major-collapse) {
                display: none;
                margin-bottom: 0;
            }

            li {
                a {
                    &.mobile {
                        @include resize($major-collapse) {
                            display: block;
                        }
                    }
                }
            }
        }

        .hamburgers {
            display: none;
            margin-top: 5px;
            margin-right: -5px;
            @include resize($major-collapse) {
                display: block;
            }
        }

        .search.container {
            width: 100%;
            display: block;
            @include resize($major-collapse) {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: none;
                position: fixed;
                align-items: center;
                justify-content: center;
                background-color: rgba(255, 255, 255, 0.95);

                &.shown {
                    display: flex;
                }

                input {
                    height: 50px;
                    border-color: transparent !important;
                    border-bottom: 2px solid $font-light !important;
                    background-color: transparent !important;
                    @include get-radius(0 !important);
                }
            }

            > div {
                width: 100%;
                text-align: center;
                @include resize($major-collapse) {
                    padding: 0 50px;
                    margin-top: -10px;
                }

                .date {
                    color: $font-light;
                    display: inline;
                    font-size: 14px;
                    font-weight: 600;
                    padding-right: 10px;

                    @include md-only() {
                        font-size: 12px;
                    }
                    @include resize($major-collapse) {
                        display: none;
                    }
                }

                .om.logo {
                    margin: 0 auto 40px;
                    display: none;
                    @include resize($major-collapse) {
                        display: block;
                    }
                }

                .close {
                    top: 30px;
                    right: $mobile-padding-h + 5px;
                    display: none;
                    padding: 10px;
                    position: absolute;
                    font-size: 26px;

                    @include resize($major-collapse) {
                        display: block;
                    }
                }

                .ui.large.icon.input {
                    @include xs() {
                        width: 100%;
                        input {
                            padding-left: 10px;
                        }
                    }

                    @include sm-only() {
                        width: 50%;
                        input {
                            padding-left: 10px;
                        }
                    }
                }
            }
        }
    }
}
