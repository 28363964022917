.aibim {
    &.menu {
        color: $white;
        width: 100%;
        height: auto;
        font-weight: 700;
        background-color: $darker-blue;
        @include resize($major-collapse) {
            padding: 0;
            display: none;
        }
        @include lg() {
            padding: 0 $topbar-padding-h;
        }

        ul {
            margin: 0;
            padding: 0;
            display: table;
            list-style: none;

            li {
                display: table-cell;
                position: relative;
                vertical-align: middle;

                &:hover {
                    background-color: $blue;
                    @include get-transition();

                    &.with-image {
                        a,
                        div {
                            img {
                                $glow: drop-shadow(0 0 5px rgb(59, 177, 255));

                                -webkit-filter: $glow;
                                -webkit-filter: $glow;
                                -ms-filter: $glow;
                                filter: $glow;
                            }
                        }
                    }
                }

                &.with-image {
                    a,
                    div {
                        padding: 10px 15px;
                    }
                }

                a,
                div {
                    color: inherit;
                    display: block;
                    padding: 25px 23px 20px;
                    position: relative;

                    img {
                        $glow: brightness(400%), contrast(200%), drop-shadow(0 0 5px rgb(0, 0, 0));

                        top: 4px;
                        width: auto;
                        height: 30px;
                        position: relative;
                        -webkit-filter: $glow;
                        -webkit-filter: $glow;
                        -ms-filter: $glow;
                        filter: $glow;
                    }
                }

                p {
                    margin: 0;
                }

                &:hover {
                    > .submenu {
                        display: block;
                    }
                }

                > .submenu {
                    width: 230px;
                    padding: 0;
                    z-index: 99;
                    display: none;
                    position: absolute;
                    padding-top: 10px;

                    ul {
                        background-color: $white;
                        @include get-radius($std-radius);
                        @include get-shadow();

                        li {
                            color: $font-color;
                            width: 230px;
                            display: block;
                            font-size: 12px;
                            font-weight: 600;
                            &:hover {
                                background-color: #eaf0f5;
                            }
                            &:first-of-type {
                                @include get-positioned-radius($std-radius, top-left);
                                @include get-positioned-radius($std-radius, top-right);
                            }
                            &:last-of-type {
                                @include get-positioned-radius($std-radius, bottom-left);
                                @include get-positioned-radius($std-radius, bottom-right);
                            }

                            a,
                            div {
                                padding: 15px;
                            }
                        }
                    }
                }
            }
        }

        // Push Menu Variant
        &.push {
            top: 0;
            color: $font-color;
            width: $sidebar-width;
            height: 100%;
            z-index: 99;
            display: block;
            padding: 0;
            position: fixed;
            background-color: $white;
            @include get-shadow(10px 0 15px rgba(0, 0, 0, 0.05));
            @include xs() {
                width: 300px;
            }

            .content {
                width: 100%;
                height: 100%;
                padding: 20px 0;
                overflow-y: auto;
                @extend %no-scrollbar;

                .header {
                    padding: 0 20px 20px;
                    text-align: center;

                    .om.logo {
                        margin: 0 auto 20px;
                    }

                    .social {
                        margin-bottom: 20px;
                        .item {
                            display: inline-block;
                            font-size: 20px;
                            a {
                                padding: 5px;
                                @each $name, $color in $social-colors {
                                    &.#{$name} {
                                        color: $color !important;
                                    }
                                }
                            }
                        }
                    }
                }

                ul {
                    li {
                        display: block;
                        &:hover,
                        &:focus,
                        &:active {
                            background-color: $white;
                        }

                        a {
                            &:hover,
                            &:focus,
                            &:active {
                                background-color: inherit;
                            }
                        }

                        ul {
                            margin-top: -5px;
                            padding-left: 33px;
                            li {
                                font-weight: normal;
                                a {
                                    padding: 13px 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.menu-overlay {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 98;
        position: fixed;
        background: rgba(12, 18, 24, 0.85);
    }

    &.floating-menu {
        $padding-x: 16px;
        $padding-y: 13px;

        top: $topbar-height + 180px;
        right: 0;
        z-index: 10000;
        position: fixed;
        background-color: $white;
        @include get-shadow();
        @include get-positioned-radius($std-radius, top-left);
        @include get-positioned-radius($std-radius, bottom-left);

        @include xs() {
            top: $topbar-height + 70px;
        }

        a,
        .item {
            width: 100%;
            color: $font-light;
            cursor: pointer;
            display: block;
            padding: $padding-y $padding-x;
            position: relative;
            text-align: center;

            &:first-of-type {
                @include get-positioned-radius(inherit, top-left);
            }
            &:last-of-type {
                @include get-positioned-radius(inherit, bottom-left);
            }

            &:hover {
                color: $font-color;
                background: lighten($base, 5%);
                @include get-transition();
            }

            .om.dot {
                $size: 7px;
                top: $padding-y - 3px;
                right: $padding-x - 3px;
                width: $size;
                height: $size;
                position: absolute;
            }

            svg {
                font-size: 16px;
            }
        }
    }
}
