.om.carousel {
    $control-color: $white;

    width: 400px;
    height: 400px;
    overflow: hidden;
    position: relative;
    visibility: visible;
    background-color: darken($base, 3%);

    .control {
        color: $control-color;
        cursor: pointer;
        padding: 20px;
        font-size: 24px;
    }

    .dots {
        padding: 15px 0;
        .dot {
            $size: 8px;

            width: $size;
            height: $size;
            margin: 0 3px;
            cursor: pointer;
            display: inline-block;
            opacity: 0.5;
            background-color: $control-color;
            @include get-radius($size/2);
            @include get-transition(all 0.3s ease);

            &.active {
                width: $size * 2;
                cursor: default;
                opacity: 1;
                background-color: $control-color;
            }
        }
    }

    > div {
        .react-parallax-bgimage {
            img {
                position: relative !important;
            }
        }

        img {
            position: fixed;
            object-fit: cover;
            object-position: center;
        }
    }

    .parallax-outer {
        width: 100%;
        height: 100%;
        margin: 0;
        position: absolute;
        .parallax-inner {
            @include xs() {
                width: inherit;
                height: inherit;
                transform: translate3d(0%, 0%, 0px) !important;
            }
        }
    }

    .ui.dimmer {
        background-color: transparent;
    }

    // Nuka Carousel Configurations
    ///////////////////////////////////
    ul.slider-list {
        height: inherit !important;
        li.slider-slide {
            height: inherit !important;
            > div,
            > img,
            > span {
                height: inherit !important;
                cursor: default;
                display: block;
                position: relative;
                overflow: hidden;
                &:focus {
                    outline: none !important;
                }

                .overlay {
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 2;
                    position: absolute;
                    filter: brightness(-10%) contrast(-200%);
                    -ms-filter: brightness(-10%) contrast(-200%);
                    -webkit-filter: brightness(-10%) contrast(-200%);
                    background-color: rgba(0, 0, 0, 0.5);
                }

                img {
                    width: 100%;
                    height: 100%;
                    z-index: 1;

                    position: relative;
                    object-fit: cover !important;
                    object-position: center;
                }
            }
        }
    }
}
