.om.empty {
    height: 100%;
    display: flex;
    padding: 70px 20px;
    align-items: center;
    justify-content: center;

    @include xs() {
        height: auto;
        display: block !important;
        min-height: 500px;
    }

    div {
        text-align: center;
        .ui.header {
            display: block;
            text-align: inherit;
            margin-bottom: 5px;
            .sub.header {
                text-align: inherit;
            }
        }
    }

    img {
        width: 300px;
        height: auto;
    }
}
