.aibim.highlight {
    width: 100%;
    height: auto;
    margin: 30px 0;
    position: relative;
    background-color: $darker-blue;

    .decorative {
        width: 100%;
        height: 0;
        background-size: cover;
        background-repeat: no-repeat;

        &.top {
            top: 0;
            position: relative;
            padding-top: percentage(113/1150);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1150' height='113' viewBox='0 0 1150 113'%3E%3Cpath id='Path_82' data-name='Path 82' d='M0,0H1150V113L863.42,20,0,113Z' stroke='%23fff' strokeWidth='2' fill='%23fff'/%3E%3C/svg%3E%0A");
        }

        &.bottom {
            bottom: 0;
            position: relative;
            padding-top: percentage(116/1150);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1150' height='116' viewBox='0 0 1150 116'%3E%3Cpath id='Path_83' data-name='Path 83' d='M0,0,575,88.178,1150,0V116H0Z' stroke='%23fff' strokeWidth='2' fill='%23fff'/%3E%3C/svg%3E%0A");
        }
    }

    .highlight-content {
        padding: 0 130px;
        min-height: 200px;

        $content-bp: (
            max: 1500px,
        );
        @include breakpoint($content-bp) {
            padding: 0 50px;
        }
        @include xs() {
            padding: 0 10px 30px;
        }

        > .ui.grid {
            > .column {
                @include md-only() {
                    width: 50% !important;
                }

                .card {
                    z-index: 3;
                    display: block;
                    position: relative;
                    font-size: 16px;
                    min-height: 420px;
                    font-weight: 600;

                    filter: brightness(110%);
                    -webkit-filter: brightness(110%);
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: bottom right;
                    @include get-radius($std-radius);
                    @include get-shadow($highlight-shadow);
                    @include xs() {
                        min-height: 470px;
                    }

                    &:hover {
                        @include resize((sm-only, md-only, lg)) {
                            @include get-shadow($highlight-shadow-hover);
                            @include get-transition(0.5s);
                        }
                    }

                    > .content {
                        ul {
                            margin-bottom: 40px;
                            padding-inline-start: 20px;
                            li {
                                margin: 20px 0;
                                line-height: 22px;
                                &:first-of-type {
                                    margin-top: 0;
                                }
                                &:last-of-type {
                                    margin-bottom: 0;
                                }

                                a {
                                    color: $font-color;
                                    &:hover {
                                        color: $blue;
                                    }
                                }
                            }
                        }

                        .ui {
                            &.header {
                                margin-bottom: 30px;
                            }
                            &.button {
                                bottom: 30px;
                                position: absolute;
                                @include xs() {
                                    bottom: 25px;
                                }
                            }
                        }
                    }
                }

                &:nth-child(1) {
                    .card {
                        background-image: url(../../../media/luca-bravo-alS7ewQ41M8-unsplash.jpg);
                    }

                    @include lg() {
                        bottom: -50px;
                    }
                    @include md-only() {
                        margin-top: -50px;
                    }
                    @include sm-only() {
                        top: -40px;
                    }
                    @include xs() {
                        margin-top: -50px !important;
                    }
                }
                &:nth-child(2) {
                    .card {
                        background-image: url(../../../media/photo-1492999104346-cabaa757be8f.jpeg);
                    }

                    @include lg() {
                        top: -100px;
                    }
                    @include md-only() {
                        top: 130px;
                    }
                    @include sm-only() {
                        top: 130px;
                    }
                }
                &:nth-child(3) {
                    .card {
                        background-image: url(../../../media/renata-adrienn-ebvCsRypmxM-unsplash.jpg);
                    }

                    @include lg() {
                        top: -20px;
                    }
                    @include md-only() {
                        margin-top: -50px;
                    }
                    @include sm-only() {
                        bottom: -20px;
                        margin-top: -50px;
                    }
                }
            }
        }
    }
}
