/**
 * Begin Semantic-UI Overrides
 * 
 * @author  Omar
 * @since   1.0
 **/
html,
body {
    color: $font-color;
    margin: 0;
    height: 100%;
    font-size: $font-size;
    min-height: 100%;
    font-family: $font-family !important;
    background-color: $background;

    &.dimmable {
        &.blurring {
            .global {
            }
        }
    }

    &.no-overflow-x {
        overflow-x: hidden;
    }
}

*::selection {
    color: $white !important;
    background-color: $accent !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
    font-family: $font-family;
}

textarea {
    height: auto !important;
    resize: none;
}

@each $name, $vals in $heading-sizes {
    #{$name} {
        $font-size: map-get($vals, font-size);
        font-size: $font-size;
        font-weight: 600;
        &.ui.header {
            font-size: $font-size;
            font-family: $font-family;
            .sub.header {
                font-size: map-get($vals, sub-font-size);
                line-height: map-get($vals, sub-font-size) + 4px;

                i,
                svg {
                    &:last-child {
                        margin-left: 5px;
                    }
                }
            }

            .index {
                color: inherit;
                display: inline-block;
                font-size: $font-size - 4px;
                font-weight: bold;
                margin-left: 8px;
                i,
                svg {
                    position: relative;
                    font-size: $font-size - 6px;
                }

                @each $n, $v in $colors {
                    &.#{$n} {
                        color: $v;
                    }
                }
            }
        }
    }
}

* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

a {
    color: $blue;
    cursor: pointer;
    &:hover {
        color: lighten($blue, 10%);
        @include get-transition();
    }
}

b {
    font-weight: 600;
}

.global {
    width: 100%;
    height: auto;
    // display: flex;
    padding: 0 !important;
    position: relative;
    min-height: 100%;
    // flex-direction: column;
    background-color: $background;
}

.grecaptcha-badge {
    opacity: 0 !important;
}

.aibim {
    &.video {
        height: 100% !important;
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        justify-content: center;

        iframe {
            width: 100%;
            height: 100%;
            @include get-radius($std-radius);
        }

        @include xs() {
            height: auto;
            display: block;
            padding-bottom: 5px;
        }
    }

    &.prayer-times {
        $padding: 8px;
        width: 100%;
        display: table;
        position: relative;

        .row {
            display: table-row;

            .label {
                width: 50%;
                display: table-cell;
                padding: $padding 0;
                padding-left: 5px;
                font-weight: 600;
            }

            .value {
                width: 50%;
                display: table-cell;
                padding: $padding 0;
                text-align: right;
                font-weight: 600;
                padding-right: 5px;
            }

            &:nth-child(odd) {
                background-color: lighten($base, 4%);
            }
        }
    }
}

.container {
    &.main {
        width: 100%;
        position: relative;
        min-height: 100%;
        @include get-transition(all 0.6 ease);

        &.blur {
            @include blur(3px);
        }
        &.pushed {
            left: $sidebar-width;
            overflow-x: hidden;
        }

        .body {
            @include resize($major-collapse) {
                margin-top: 80px;
            }
        }
    }

    &.content {
        $content-bp: (
            max: 1350px,
        );

        z-index: 10;
        position: relative;
        margin-top: -50px;
        @include breakpoint($content-bp) {
            padding: 0 10% $padding-y * 2;
        }
        @include resize($major-collapse) {
            padding: 0 $mobile-padding-h $padding-y;
        }
        @include xs() {
            padding: 0 $mobile-padding-h - 10px $padding-y;
        }
        @include lg() {
            padding: 0 $padding-x $padding-y * 2;
        }

        .footnote {
            color: $font-light;
            padding: 0 5px;
            font-size: 12px;
            margin-top: 10px;

            @include lg() {
                padding-top: 5px;
            }

            a {
                color: $font-light;
                &:hover {
                    color: darken($font-light, 10%);
                }
            }

            .ui.grid {
                .column {
                    @include xs() {
                        text-align: center;
                        &:first-of-type {
                            padding: $padding-y 0 5px !important;
                        }
                        &:last-of-type {
                            padding-top: 0 !important;
                        }
                    }
                }
            }
        }
    }

    &.top {
        @include resize($major-collapse) {
            top: 0;
            width: 100%;
            z-index: 99;
            position: fixed;
            @include get-shadow(0 5px 10px rgba(0, 0, 0, 0.2));
        }

        $anim: 0.3s;
        .aibim.topbar {
            .om.logo {
                @include get-transition(all $anim ease);
            }
        }

        &.sticky {
            $shadow: rgba(0, 0, 0, 0.08);
            top: 0;
            width: 100%;
            z-index: 999;
            padding: 0 !important;
            position: fixed;
            @include get-shadow(
                (
                    0 0 2px $shadow,
                    0 2px 4px $shadow,
                    0 4px 8px $shadow,
                    0 8px 16px $shadow,
                    0 16px 32px $shadow
                )
            );

            .aibim.topbar {
                @include resize((sm, md, lg)) {
                    height: auto;
                    padding: 20px 50px;
                    @include get-transition(all $anim ease);

                    .om.logo {
                        $new-width: 100px;
                        width: $new-width;
                        height: aspectHeight($logo-width, $logo-height, $new-width);
                        @include get-transition(all $anim ease);
                    }

                    .left {
                        .tagline {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &.search {
        .item {
            margin-bottom: 35px;
            &:last-of-type {
                margin-bottom: 0;
            }

            p {
                margin: 3px 0 0;
                line-height: 20px;
            }

            .ui.header {
                color: $blue;
                margin: 0 0 0;
                .sub.header {
                    color: $green;
                    font-size: 12px;
                }
            }
        }
    }
}

.scrollable {
    max-height: 300px;
    @extend %scrollable;
}

.responsive.table {
    padding-bottom: 10px;
    overflow-x: auto;
}

.text {
    $colors: map-merge($colors, $role-colors);
    @each $name, $color in $colors {
        &.#{$name} {
            color: $color !important;
        }
    }
    @each $name, $color in $social-colors {
        &.#{$name} {
            color: $color !important;
        }
    }

    &.light {
        color: $font-light !important;
    }
    &.lighter {
        color: lighten($font-light, 5%) !important;
    }
}

.button.container {
    padding-top: 20px;
    &.bordered {
        border-top: 1px solid lighten($border-color, 8%);
    }

    .ui.button {
        margin: 0 2px;
        &:first-of-type {
            margin-left: 0;
        }
    }
    &.left {
        text-align: left;
        .ui.button {
            &:first-of-type {
                margin-left: 0;
            }
        }
    }
    &.right {
        text-align: right;
        .ui.button {
            &:last-of-type {
                margin-right: 0;
            }
        }
    }
    &.center {
        text-align: center;
    }
}

.tooltip {
    position: relative;

    &.anchor {
        cursor: pointer;
    }

    .text {
        left: 50%;
        color: $white;
        width: auto;
        padding: 3px 8px;
        z-index: 10;
        position: absolute;
        font-size: 10px;
        visibility: hidden;
        text-align: center;
        margin-top: 5px;
        font-weight: 600;
        white-space: nowrap;
        background-color: $tooltip-color-bg;
        @include get-radius(3px);
        @include css-transform(translateX(-50%));

        &::after {
            content: " ";
            position: absolute;
            bottom: 100%; /* At the top of the tooltip */
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent $tooltip-color-bg transparent;
        }
    }

    &:hover {
        .text {
            visibility: visible;
            @include get-transition(3s);
        }
    }
}

ul.social {
    margin: 0 0 10px;
    padding: 0;
    font-size: 20px;
    list-style: none;

    li {
        margin: 0;
        display: inline-block;
        position: relative;

        &:first-of-type {
            a {
                padding-left: 0;
            }
        }

        &:last-of-type {
            a {
                padding-right: 0;
            }
        }

        a {
            color: inherit;
            display: block;
            padding: 6px;

            &.text {
                display: block;
            }

            @each $name, $color in $social-colors {
                &.#{$name} {
                    color: $color !important;
                }
            }

            &.mobile {
                color: $font-light;
                display: none;
                font-size: 18px;
            }
        }
    }
}

details {
    margin: 0 0 10px;

    summary {
        cursor: pointer;
        border: 1px solid lighten($border-color, 5%);
        padding: 10px;
        font-size: 14px;
        font-weight: 600;
        border-bottom-width: 2px;
        @include get-radius($std-radius);

        &:focus {
            outline: none !important;
        }
    }

    div.body {
        margin: 5px 0 20px;
        padding: 5px;
        text-align: justify;

        p {
            margin: 0 0 15px !important;
            &:last-of-type {
                margin-bottom: 0 !important;
            }
        }
    }

    &[open] {
        summary {
            color: $blue;
            border-color: darken($border-color, 5%);
            // background-color: lighten($base, 5%);
            border-bottom-width: 1px;
        }
    }
}

.om {
    &.shimmer {
        @include shimmer(#f6f7f8, #edeef1);
    }

    @keyframes placeholderShimmer {
        0% {
            background-position: -468px 0;
        }

        100% {
            background-position: 468px 0;
        }
    }

    &.tabs.overflow {
        @include resize((sm, md-only, lg)) {
            overflow-x: scroll;
            overflow-y: hidden;
            white-space: nowrap;
            -webkit-overflow-scrolling: touch;

            scrollbar-width: none; /* Firefox */
            -ms-overflow-style: none; /* IE 10+ */
            &::-webkit-scrollbar {
                height: 0px;
                background: transparent; /* make scrollbar transparent */
            }
        }
    }

    &.modal.wrapper {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99999;
        display: flex;
        padding: 20px;
        position: fixed;
        overflow-y: auto;
        background: $modal-overlay;
        align-items: center;
        justify-content: center;

        .container {
            width: 800px;
            max-height: 100%;
            margin-bottom: 20px;
        }

        $modal-sizes: (
            mini: 30%,
            tiny: 600px,
            small: 700px,
            medium: 800px,
            large: 900px,
        );

        @each $k, $v in $modal-sizes {
            &.#{$k} {
                .container {
                    width: $v;
                }
            }
        }
    }

    &.list {
        margin: 0;
        padding: 0;
        list-style: none;

        .item {
            margin: 0 0 5px;
            padding: 0;
            display: table;
            list-style: none;

            &:last-child {
                margin-bottom: 0;
            }

            .icon {
                display: table-cell;
                position: relative;
                min-width: 20px;
                text-align: center;

                i,
                svg {
                    top: 0;
                    left: -3px;
                    position: relative;
                }

                & + span {
                    display: table-cell;
                    padding-left: 2px;
                }
            }
        }
    }

    &.message {
        border: 1px solid lighten($message-color-border, 40%);
        padding: 15px;
        position: relative;
        border-top-width: 4px;
        border-top-color: $message-color-border;
        margin-bottom: 10px;
        background-color: $white;
        @include get-radius(5px);

        .ui.header {
            font-size: inherit;
            .sub.header {
                font-size: inherit;
            }
        }

        &.with-icon {
            $icon-size: 50px;
            width: 100%;
            display: table;

            .icon {
                width: $icon-size;
                height: $icon-size;
                display: table-cell;
                vertical-align: middle;

                .orb {
                    width: $icon-size;
                    height: $icon-size;
                    border: 7px solid lighten($font-light, 35%);
                    display: flex;
                    font-size: $font-size + 4px;
                    align-items: center;
                    justify-content: center;
                    @include get-radius($icon-size / 2);
                }
            }

            .text {
                padding-left: 15px;
                display: table-cell;
                position: relative;
                vertical-align: middle;
            }
        }

        @each $name, $color in $colors {
            &.#{$name} {
                border-top-color: $color;
                &.with-icon {
                    .icon {
                        .orb {
                            color: $color;
                            border-color: lighten($color, map-get($label-opacity, $name));
                        }
                    }
                }
            }
        }
    }

    &.dot {
        width: 6px;
        height: 6px;
        display: block;
        background-color: $font-color;
        @include get-radius(6px);

        /**
         * Pulsating effect for dot.
         * 
         * @author  craigs
         * @link    https://stackblitz.com/edit/react-pulsating-dot
         **/
        &.pulsating {
            animation: pulseDot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
            box-sizing: border-box;
            border-radius: 50%;
            background-color: $font-color;

            &:before {
                top: -100%;
                left: -100%;
                width: 300%;
                height: 300%;
                content: "";
                display: block;
                position: relative;
                animation: pulseRing 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
                border-radius: 45px;
                background-color: $font-color;
            }
        }

        @each $name, $color in $colors {
            &.#{$name} {
                background-color: $color;
                &.pulsating {
                    &:before {
                        background-color: $color;
                    }
                }
            }
        }

        @each $name, $color in $role-colors {
            &.#{$name} {
                background-color: $color;
                &.pulsating {
                    &:before {
                        background-color: $color;
                    }
                }
            }
        }

        @keyframes pulseRing {
            0% {
                transform: scale(0.5);
            }
            80%,
            100% {
                opacity: 0;
            }
        }

        @keyframes pulseDot {
            0%,
            100% {
                transform: scale(1);
            }
            50% {
                transform: scale(1.1);
            }
        }
    }

    &.dp {
        color: $font-light;
        width: 40px;
        height: 40px;
        cursor: pointer;
        display: inline-block !important;
        position: relative;
        background-color: $field-bg;
        @include get-radius(4px);

        &.circular {
            @include get-radius(20px);
        }
        &.square {
            @include get-radius(0);
        }
        &.rounded {
            @include get-radius(4px);
        }

        &.dicebear {
            display: flex !important;
            text-align: center;
            align-items: center;
            justify-content: center;

            .image {
                width: 65%;
                height: 65%;
                display: inline-block;
                background-size: 100%;
                @include get-radius(0 !important);
            }
        }

        &.vector {
            display: flex !important;
            padding: 2px;
            align-items: center;
            justify-content: center;

            @each $name, $color in $colors {
                &.#{$name} {
                    background: $color;

                    i,
                    svg {
                        color: lighten($color, 30%);
                        font-size: $font-size + 2px;
                    }
                }
            }
        }

        &:hover {
            background-color: darken($field-bg, 5%);
            @include get-transition();
        }

        &:active {
            background-color: darken($field-bg, 10%);
        }

        .image {
            width: inherit;
            height: inherit;
            display: flex;
            align-items: center;
            justify-content: center;
            background-size: 40px 40px;
            background-position: center;
            @include get-radius(inherit);
        }

        .overlay {
            top: 0;
            left: 0;
            right: 0;
            color: $white;
            bottom: 0;
            display: flex;
            position: absolute;
            font-size: $font-size;
            align-items: center;
            justify-content: center;
            background-color: $dp-overlay;
            @include get-radius(inherit);
        }
    }

    &.logo {
        width: $logo-width;
        height: $logo-height;
        display: block;
        background: url(../../media/logo.svg);
        background-size: cover;
    }
}

/**
 * Semantic UI direct overrides
 *
 * @since   1.0
 **/

select,
textarea,
input[type="tel"],
input[type="url"],
input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"] {
    @extend %default-form-input;
}

.ui {
    &.modals {
        &.dimmer {
            top: 0 !important;
            left: 0 !important;
            right: 0 !important;
            bottom: 0 !important;
            background-color: $modal-overlay !important;
        }
    }

    &.button {
        color: $font-color;
        border: 1px solid darken($background, 5%);
        padding: 15px 18px;
        font-size: 12px;
        font-weight: 600;
        font-family: $font-family;
        text-transform: uppercase !important;
        background-color: darken($background, 5%);
        @include get-shadow(none !important);

        &:hover {
            border-color: darken($background, 10%);
            background-color: darken($background, 10%);
        }
        &:active {
            border-color: darken($background, 15%);
            background-color: darken($background, 15%);
        }

        &.loading,
        &.isLoading {
            opacity: 1 !important;
        }

        &.transparent {
            border-color: transparent !important;
            background-color: transparent !important;
            @each $name, $color in $colors {
                &.#{$name} {
                    color: $color;
                    border-color: transparent !important;
                    &:hover,
                    &:active {
                        border-color: transparent !important;
                    }
                }
            }
        }

        @each $name, $color in $colors {
            &.#{$name} {
                @if ($name == white) {
                    color: $font-color;
                } @else {
                    color: $white;
                }

                border: 1px solid $color !important;
                background-color: $color;
                &:hover {
                    border-color: darken($color, 5%) !important;
                    background-color: darken($color, 5%);
                }
                &:active {
                    border-color: darken($color, 10%) !important;
                    background-color: darken($color, 10%);
                }
                &:focus {
                    border: 1px solid $color !important;
                    background-color: $color;
                }
            }
        }

        &.basic {
            color: lighten($font-color, 15%) !important;
            border: 1px solid $border-color;
            font-weight: 600;
            background-color: $white !important;
            &:hover {
                color: $font-color !important;
                border-color: darken($border-color, 5%);
            }
            &:active {
                background-color: lighten($border-color, 11%) !important;
            }
        }

        &.circular {
            // Button Sizes
            @each $name, $arr in $input-circular-sizes {
                &.#{$name} {
                    padding: map-get($arr, padding);
                    font-size: map-get($arr, font-size) - 2px;
                }
            }
        }

        // Button Sizes
        @each $name, $arr in $input-sizes {
            &.#{$name} {
                padding: map-get($arr, padding);
                font-size: map-get($arr, font-size) - 2px;
            }
        }

        .buttonicon {
            font-size: inherit;
            margin-right: 6px;
            &.only {
                margin-right: 0;
            }
        }
    }

    &.modal {
        $radius: 10px;
        @include get-radius($radius !important);

        > .content,
        > .actions {
            background: transparent;
        }

        > .content {
            overflow: hidden;
            @include get-positioned-radius(inherit, top-left);
            @include get-positioned-radius(inherit, top-right);

            .ui {
                &.header {
                    &.title {
                        margin-bottom: 30px;
                    }
                }

                &.form {
                    @extend %form-on-white;
                }
            }
        }

        > .actions {
            border-top-color: $border-color;
            @include get-positioned-radius(inherit, bottom-left);
            @include get-positioned-radius(inherit, bottom-right);
        }

        &.poster {
            > .content {
                padding: 0;

                &.image {
                    width: 100%;
                    height: auto;
                    line-height: 0;
                    img {
                        @include get-radius($radius !important);
                    }
                }
            }
        }
    }

    &.header {
        color: $font-color;
        display: table;
        position: relative;
        font-weight: 600;
        font-family: $font-family;
        margin-bottom: 15px;

        &.center.aligned {
            display: block;
            text-align: center;
        }

        &.section-header {
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }

        .content {
            display: inline-block;
            padding: 0 !important;
            padding-left: 5px !important;
        }

        .icon {
            top: 2px;
            display: table-cell;
            position: relative;
            font-size: inherit;
            padding-top: 0;
            margin-right: 10px;
            vertical-align: middle;

            &:only-child {
                top: -1px;
                display: inline-block;
            }
        }

        div.icon {
            padding-right: 5px;
        }

        & > .icon + .content {
            display: table-cell;
            vertical-align: middle;
        }

        &.with-sub {
            .icon {
                margin-top: -5px;
            }
        }

        a {
            color: inherit;
        }

        .om.dot {
            top: -1px;
            display: inline-block;
            position: relative;
            margin-left: 2px;
        }

        .sub.header {
            color: $font-light;
            a {
                color: inherit;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        @each $name, $color in $colors {
            &.#{$name} {
                color: $color !important;
            }
        }
    }

    &.message {
        color: $font-color !important;
        border: 3px solid $border-color !important;
        background-color: $white !important;
        @include get-shadow(none !important);
    }

    &.menu {
        font-family: $font-family !important;
        border-color: $border-color;
        .item {
            cursor: pointer;
            &:hover {
                background-color: lighten($light-grey, 1%) !important;
                @include get-transition();
            }
            &:active {
                background-color: darken($light-grey, 1%) !important;
                @include get-transition(none !important);
            }

            svg,
            .icon {
                color: $font-light;
                margin-right: 10px;
            }
        }

        &.secondary {
            &.pointing {
                margin-bottom: 20px;
                .item {
                    font-weight: 600 !important;
                    &:hover {
                        border-color: $border-color;
                    }
                }

                @include xs() {
                    display: block;
                    border-bottom: none;
                    .item {
                        width: 100%;
                        padding: 15px 15px !important;
                        border-bottom: none;
                        &.active {
                            border-left: 2px solid $font-color;
                            padding-left: 15px !important;
                        }
                    }
                }
            }
        }

        &.pagination {
            border-color: $border-color;
            .item {
                color: $font-color !important;
                font-weight: 500;
                background-color: $white;

                &:hover {
                    background-color: lighten($base, 2%) !important;
                    @include get-transition();
                }

                &:active {
                    background-color: lighten($base, 1%) !important;
                }

                &.disabled {
                    color: lighten($font-color, 50%) !important;
                    background-color: $white !important;
                }

                &.active {
                    cursor: default;
                    background-color: lighten($base, 1%) !important;
                }

                &.icon {
                    svg {
                        margin: 0;
                    }
                }
            }
        }
    }

    &.table {
        $table-bg: lighten($background, 2%);
        $table-radius: 5px;

        color: $font-color !important;
        border: none !important;
        @include get-radius($table-radius);

        .om.header {
            margin-bottom: 0 !important;
        }

        .button.container {
            padding-top: 0;
        }

        .unit {
            color: $font-light;
            display: inline-block;
            font-size: 80%;
            padding-right: 2px;
            &.lefty {
                left: 10px;
                position: absolute;
            }
            &.righty {
                right: 10px;
                position: absolute;
            }

            &.left {
                padding-left: 2px;
                padding-right: 0;
            }

            &.right {
                padding-left: 2px;
                padding-right: 0;
            }
        }

        &.basic {
            border-color: $border-color;
        }

        a {
            color: $blue;
            &:hover {
                color: lighten($blue, 15%);
            }
        }

        &.row-highlight {
            tbody {
                tr {
                    &:hover {
                        td {
                            color: $blue !important;
                            background-color: $table-bg !important;
                        }
                    }
                }
            }
        }

        thead,
        tfoot {
            border-color: $border-color !important;
            tr {
                th {
                    color: inherit !important;
                    position: relative;
                    font-weight: bold;
                    border-color: $border-color !important;
                    background-color: $table-bg;
                }
            }
        }

        tbody {
            border-color: $border-color !important;
            tr {
                border-color: $border-color !important;

                td {
                    position: relative;
                }

                // Target bottom-left and bottom-right td, make radius follow parent.
                &:last-of-type {
                    td {
                        &:first-of-type {
                            @include get-positioned-radius($table-radius, bottom-left);
                        }

                        &:last-of-type {
                            @include get-positioned-radius($table-radius, bottom-right);
                        }
                    }
                }
            }
        }

        &.celled {
            border: 1px solid $border-color !important;
            &.striped {
                tbody {
                    tr {
                        td {
                            border-top: none !important;
                        }
                    }
                }
            }

            thead {
                tr {
                    th {
                        border-color: $border-color !important;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        &.unit {
                            border-right: none !important;
                        }
                        &.value {
                            border-left: none !important;
                        }
                    }
                }
            }
        }

        &.striped {
            tbody {
                tr {
                    td {
                        border-color: $border-color !important;
                    }

                    &:nth-child(2n) {
                        td {
                            border-top: none;
                            background-color: lighten($table-bg, 2%);
                        }
                    }
                }
            }
        }

        &.structured {
            tbody {
                tr:last-of-type {
                    td {
                        &:last-of-type,
                        &:first-of-type {
                            @include get-positioned-radius(0 !important, bottom-left);
                            @include get-positioned-radius(0 !important, bottom-right);
                        }
                    }
                }
            }

            tfoot {
                tr:first-child {
                    td,
                    th {
                        &:only-child,
                        &:last-child,
                        &:first-child {
                            @include get-positioned-radius(0 !important, bottom-left);
                            @include get-positioned-radius(0 !important, bottom-right);
                        }
                    }
                }
            }

            thead {
                tr:first-child {
                    td,
                    th {
                        &:only-child,
                        &:last-child,
                        &:first-child {
                            @include get-positioned-radius(0 !important, top-left);
                            @include get-positioned-radius(0 !important, top-right);
                        }
                    }
                }
            }
        }
    }

    &.statistic {
        color: $font-color !important;
        font-family: $font-family !important;
        .value,
        .label {
            color: inherit !important;
            font-family: inherit !important;
        }

        .label {
            text-transform: lowercase !important;
        }
    }

    &.label {
        padding: 5px 8px;
        text-transform: uppercase;

        .icon {
            margin-right: 3px;
        }

        &.anchor {
            cursor: pointer;
            display: inline-block;
        }

        &.mini {
            padding: 4px 5px;
        }
        &.tiny {
            padding: 5px 6px;
        }
        &.small {
            padding: 5px 7px;
        }

        &.no.margin {
            margin: 0;
        }

        &.pill {
            @include get-radius(50vh);
        }

        @each $name, $color in $colors {
            &.#{$name} {
                background-color: $color;
                &.basic {
                    color: $color !important;
                    border: 1px solid $color;
                    background-color: lighten($color, map-get($label-opacity, $name)) !important;
                }
            }
        }
    }

    &.message {
        border: 0 !important;
        background-color: $background;
    }

    &.dropdown {
        padding: 20px;

        &.search {
            input.search {
                left: -1px;
                height: 100%;
                position: absolute;
            }
        }

        &.selection {
            @extend %default-form-input;

            &.visible {
                @include get-positioned-radius(0 !important, bottom-left);
                @include get-positioned-radius(0 !important, bottom-right);
            }

            .dropdown {
                &.icon {
                    height: 100%;
                    display: flex;
                    align-items: center;
                }
            }

            .menu {
                left: -1px;
                margin-top: 2px;
                border-color: $border-color !important;
                background-color: $white;
                box-sizing: content-box;
                -moz-box-sizing: content-box;
                -webkit-box-sizing: content-box;

                .item {
                    color: $font-color !important;
                    padding: 15px !important;
                    border-top: none;
                    line-height: $font-size + 4px;
                    &.active {
                        background-color: $light-grey;
                    }
                    &:hover {
                        background-color: darken($light-grey, 2%);
                        @include get-transition(0.05s);
                    }
                }
            }
        }
    }

    &.form {
        .circular {
            input[type="tel"],
            input[type="url"],
            input[type="text"],
            input[type="email"],
            input[type="number"],
            input[type="password"] {
                padding: 12px 18px;
                @include get-radius(50vh !important);
                &:focus {
                    @include get-radius(50vh !important);
                }

                @each $name, $arr in $input-circular-sizes {
                    &.#{$name} {
                        padding: map-get($arr, padding);
                    }
                }
            }

            i.icon {
                right: 8px !important;
            }
        }

        .inline {
            > input {
                padding: 0;
                border: none !important;
                background: none !important;
            }

            &.bold {
                input {
                    font-weight: 600;
                }
            }
        }

        .field {
            margin-bottom: 20px;
            &:last-of-type {
                margin-bottom: 0;
            }

            & + .button.container {
                padding-top: 0;
            }
        }

        .fields {
            &.custom {
                display: block;
                .field {
                    > .ui {
                        &.input {
                            margin-bottom: 20px;
                        }
                        &.checkbox {
                            margin-bottom: 10px;
                        }
                    }
                }

                > label {
                    display: block;
                    padding: 0 0.5rem;
                    line-height: 16px;
                    font-weight: bold;
                    margin-bottom: 5px;
                    .guide {
                        font-weight: normal;
                        margin-bottom: 5px;
                    }
                }
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        // Card found inside a form
        .card {
            @extend %form-on-white;
        }

        label {
            color: $font-color !important;
            margin-bottom: 5px !important;
            .guide {
                color: $font-light !important;
                display: block;
                font-weight: normal;
                line-height: $font-size + 2px;
            }
            .required {
                color: $red;
            }
        }

        select,
        textarea,
        input[type="tel"],
        input[type="url"],
        input[type="text"],
        input[type="email"],
        input[type="number"],
        input[type="password"] {
            @extend %default-form-input;
        }

        &.loading {
            &::after,
            &::before {
                display: none;
            }

            input,
            textarea {
                opacity: 0.8;
            }
        }

        .halves {
            $halve-list: (
                width-10: 10%,
                width-20: 20%,
                width-30: 30%,
                width-40: 40%,
                width-50: 50%,
                width-60: 60%,
                width-70: 70%,
                width-80: 80%,
                width-90: 90%,
            );

            @each $name, $width in $halve-list {
                &.#{$name} {
                    width: $width;
                }
            }
        }

        .appendable {
            display: block;
            > .group {
                width: 100%;
                display: table;
                padding-bottom: 8px;
                &:last-of-type {
                    padding-bottom: 0;
                }

                > .field,
                > .ui.input {
                    width: 100%;
                    display: table-cell;
                    vertical-align: middle;
                }

                > .ui.button {
                    display: table-cell;
                    vertical-align: middle;
                }
            }
        }
    }

    &.grid {
        &.equal.height {
            .card {
                &.heightened {
                    height: 100%;
                }
            }
        }

        &.domain-cards {
            .column {
                @include resize((xs, sm-only, md-only)) {
                    width: 100% !important;
                }
            }
        }

        &.centered-content {
            $break: (
                max: 1500px,
            );

            > .column {
                @include breakpoint($break) {
                    width: 100% !important;
                }
            }
        }

        .card {
            margin-bottom: 0;
        }
    }
}

/**
 * react-calendar overrides
 *
 * @since   1.0
 **/
.react-calendar {
    &.clean {
        width: auto;
        border: none;
        font-family: $font-family;

        .react-calendar__month-view__weekdays {
            .react-calendar__month-view__weekdays__weekday {
                font-size: $font-size - 2px;
                abbr {
                    font-size: inherit;
                    text-decoration: none;
                }
            }
        }

        .react-calendar__navigation {
            .react-calendar__navigation__label {
                font-weight: bold;

                &:focus,
                &:hover,
                &:active {
                    background: transparent !important;
                }
            }
        }

        button {
            color: $font-color;
            position: relative;
            text-align: center;
            flex-basis: none !important;
            font-family: $font-family;

            .dot {
                left: 50%;
                bottom: 6px;
                position: absolute;
                @include css-transform(translateX(-50%));
            }

            .label {
                top: 0;
                right: 0;
                display: block;
                // position: absolute;
                font-size: 70%;

                b,
                span {
                    display: inline;
                    font-weight: normal !important;
                }
            }

            &[disabled],
            &[disabled="disabled"] {
                color: lighten($font-color, 40%) !important;
                background: transparent !important;

                &:focus,
                &:hover,
                &:active {
                    color: lighten($font-color, 40%) !important;
                }
            }

            &:focus,
            &:hover,
            &:active {
                background: transparent !important;
            }

            &:hover {
                color: lighten($font-color, 20%) !important;
                @include get-transition();
            }

            &.react-calendar__tile {
                padding: 15px;
                flex-basis: none !important;

                &.react-calendar__tile--now {
                    color: lighten($blue, 10%) !important;
                    font-weight: bold;
                }

                &.react-calendar__tile--hasActive,
                &.react-calendar__tile--active {
                    $bg: lighten($base, 5%);
                    color: $blue !important;
                    background-color: $bg;
                    @include get-radius($std-radius);

                    &:hover,
                    &:focus {
                        color: darken($blue, 5%) !important;
                        background-color: $bg !important;
                    }
                }
                &.react-calendar__tile--hasActive {
                    font-weight: bold;
                }

                &.react-calendar__month-view__days__day--weekend {
                    color: $font-color;
                    // font-weight: bold;
                }

                &.react-calendar__month-view__days__day--neighboringMonth {
                    color: lighten($font-color, 40%);
                }
            }
        }

        &.large {
            margin: 0 auto;

            .react-calendar__month-view__weekdays {
                .react-calendar__month-view__weekdays__weekday {
                    font-size: $font-size;
                    abbr {
                        font-size: inherit;
                        text-decoration: none;
                    }
                }
            }

            .react-calendar__navigation {
                .react-calendar__navigation__label {
                    font-size: 18px;
                    font-weight: bold;

                    &:focus,
                    &:hover,
                    &:active {
                        background: transparent !important;
                    }
                }
            }

            button {
                &.react-calendar__tile {
                    padding: 30px !important;
                    font-size: $font-size + 2px;
                }

                .dot {
                    left: 50%;
                    bottom: 20px;
                    position: absolute;
                    @include css-transform(translateX(-50%));
                }
            }
        }
    }
}

.clear,
.clearfix,
.clearfix:after {
    @extend %clearfix;
}

.centralize,
.centralized {
    display: flex;
    align-items: center;
    justify-content: center;
}

.capitalize,
.capitalized {
    text-transform: capitalize !important;
}

.uppercase {
    text-transform: uppercase !important;
}

.lowercase {
    text-transform: lowercase !important;
}

svg {
    &.arts,
    &.illustration {
        width: auto;
        height: 100px;
    }
}
