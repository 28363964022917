%no-scrollbar {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
    }
}

%scrollable {
    overflow-y: auto;
    &.with-gradient {
        @include scrollable-gradient();
    }
    &.hidden-scrollbar {
        @extend %no-scrollbar;
    }
    &.bordered {
        border: 1px solid lighten($border-color, 5%);
        padding: 20px;
        @include get-radius(5px);
        &.shadow {
            @include get-shadow();
        }
    }
}

%default-form-input {
    color: $font-color !important;
    border: 1px solid darken($base, 2%) !important;
    outline: none !important;
    padding: 12px;
    font-family: $font-family !important;
    background-color: darken($base, 2%) !important;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    @include get-radius(5px !important);

    &::selection {
        color: $white;
        background-color: $blue !important;
    }

    .default.text,
    &::placeholder {
        color: lighten($font-light, 15%) !important;
    }

    &:focus {
        $color: darken($base, 4%);
        border-color: $color !important;
        background-color: $color !important;
        @include get-transition();
        @include get-radius(5px !important);
    }

    &.circular {
        padding: 12px 18px;
        @include get-radius(50vh !important);
        &:focus {
            @include get-radius(50vh !important);
        }

        @each $name, $arr in $input-circular-sizes {
            &.#{$name} {
                padding: map-get($arr, padding);
            }
        }
    }

    @each $name, $arr in $input-sizes {
        &.#{$name} {
            padding: map-get($arr, padding);
            font-size: map-get($arr, font-size);
        }
    }
}

%form-on-white {
    select,
    textarea,
    input[type="tel"],
    input[type="url"],
    input[type="text"],
    input[type="email"],
    input[type="number"],
    input[type="password"],
    .ui.selection.dropdown {
        border-color: $field-bg;
        background-color: $field-bg;
        &:focus {
            border-color: darken($field-bg, 2%);
            background-color: darken($field-bg, 2%);
        }
        .default.text {
            color: lighten($font-light, 15%) !important;
        }

        &.inline {
            border: none;
            background: none;
            &.bold {
                font-weight: 600;
            }
        }
    }

    .ui {
        &.selection.dropdown {
            line-height: normal;
        }
    }
}

%clearfix {
    clear: both;
    height: 0;
    display: block;
    content: " "; /* Older browser do not support empty content */
    visibility: hidden;
}

%self-clear {
    &:after {
        clear: both;
        height: 0;
        content: " ";
        display: block;
        font-size: 0;
    }
}
