@import url("https://fonts.googleapis.com/css?family=Merriweather:400,400i&display=swap");
@import "bootstrap.scss";
@import "hamburgers/hamburgers.scss";

// Import modules
///////////////////////////
@import "modules/news.scss";
@import "modules/menu.scss";
@import "modules/empty.scss";
@import "modules/topbar.scss";
@import "modules/header.scss";
@import "modules/gallery.scss";
@import "modules/carousel.scss";
@import "modules/sections/member.scss";
@import "modules/sections/footer.scss";
@import "modules/sections/highlight.scss";
