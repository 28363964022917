.aibim.header {
    $background: darken($darker-blue, 10%);

    color: $white;
    padding: $padding-y + 20px $padding-x $padding-y + 50px;
    background-color: $background;

    @include resize($major-collapse) {
        padding-left: $mobile-padding-h;
        padding-right: $mobile-padding-h;
    }

    .ui.header {
        color: $white;
        margin-bottom: 10px;
        .sub.header {
            color: lighten($blue, 20%);
        }
    }
}
