.aibim.footer {
    padding: 40px;
    background-color: $footer-base;
    @include get-positioned-radius($std-radius, bottom-left);
    @include get-positioned-radius($std-radius, bottom-right);

    .description {
        line-height: 22px;
        margin-bottom: 30px;
    }

    input[type="text"],
    input[type="phone"],
    input[type="email"],
    textarea {
        $background: #f0f4f8;
        border-color: $background !important;
        background-color: $background !important;

        &:focus {
            $background: darken($background, 2%);
            border-color: $background !important;
            background-color: $background !important;
        }
    }

    .ui.grid {
        .column {
            @include xs() {
                width: 100% !important;
            }

            @include sm-only() {
                width: 50% !important;
            }
        }
    }

    ul {
        &.social {
            margin-bottom: 10px;
        }

        &.list {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                margin-bottom: 10px;
                &:last-of-type {
                    margin-bottom: 0;
                }

                .icon {
                    width: 25px;
                    display: inline-block;

                    i,
                    svg {
                        color: $font-light;
                    }
                }
            }
        }
    }
}
