.aibim.news {
    &.mini {
        margin: 20px 0 15px;
        padding: 10px 0;
        max-height: 300px;

        .item {
            margin: 25px 0;
            &:first-of-type {
                margin-top: 0;
            }
            &:last-of-type {
                margin-bottom: 0;
            }

            a {
                color: darken($font-light, 15%);
                display: block;

                &:hover {
                    color: $blue;
                    .ui.header {
                        .sub.header {
                            color: darken($font-light, 10%);
                        }
                    }
                    .date {
                        color: $font-light;
                    }
                }

                .date {
                    color: lighten($font-light, 5%);
                    display: inline-block;
                    padding-top: 5px;
                }

                .ui.header {
                    color: inherit;
                    margin-bottom: 0;
                }
            }
        }
    }

    .om.empty {
        padding: 0;
        .ui.header {
            display: block !important;
            text-align: inherit;
        }
    }
}
