/**
 * Ohm's Admin Theme
 * 
 * @description Highly customizable admin theme built with ReactJS.
 * @author Omar Mokhtar Al-Asad @omarqe
 * @twitter https://twitter.com/omarqe
 * @site https://omarqe.com
 **/

// Color names and their respective codes
// ==================================================
$red: #e16262;
$base: #eaeef0;
$grey: #dddddd;
$white: #ffffff;
$yellow: #ffa952;
$blue: #3962cb;
$dark: #354b5f;
$dull-blue: #9da8ff;
$dark-blue: #064acb;
$light-blue: lighten($blue, 10%);
$light-grey: #f5f7f8;
$darker-blue: #110b79;
$footer-base: #f9fafc;
$green: #4bbb8b;
$limegreen: #7bc67b;

// Font colors
// ==================================================
$font-color: #485e72;
$font-light: #8695a3;
$font-dark-green: #314610;

// Backgrounds
// ==================================================
$accent: $blue;
$field-bg: $base;
$dp-overlay: rgba(28, 39, 51, 0.6);
$background: $base;
$shadow-color: rgba(0, 0, 0, 0.04);
$box-shadow: 0 2px 4px $shadow-color, 0 4px 8px $shadow-color, 0 8px 16px $shadow-color,
    0 16px 32px $shadow-color;
$alert-shadow: 0 10px 15px rgba(65, 84, 93, 0.18);
$dropdown-shadow: 0 3px 15px rgba(65, 84, 93, 0.18);
$highlight-shadow: 0 3px 15px rgba(0, 0, 0, 0.4);
$highlight-shadow-hover: 0 5px 20px rgba(0, 0, 0, 0.5);
$border-color: darken($background, 5%);
$modal-overlay: rgba(12, 18, 24, 0.9);
$scrollbar-gradient-0: rgba(190, 203, 209, 0);
$scrollbar-gradient-02: rgba(190, 203, 209, 0.2);

// Login colors
// ==================================================
$login-color-bg: $dark-blue;
$login-color-font: $white;
$login-color-shadow: darken($darker-blue, 5%);
$login-color-subheader-font: lighten($blue, 20%);

// Message colors
// ==================================================
$message-color-border: lighten($font-color, 15%);

// Card colors
// ==================================================
$card-color-bg: $white;
$card-color-bg-dark: $dark;
$card-color-bg-shade: $light-grey;
$card-color-tabs: inherit;
$card-color-tabs--active: $light-grey;

// Tooltip colors
// ==================================================
$tooltip-color-bg: $dark;

// Header colors
// ==================================================
$header-color-bg: $darker-blue;
$header-color-font: $white;
$header-color-subfont: $light-blue;
$header-color-breadcrumb: $header-color-subfont;
$header-color-breadcrumb--active: $header-color-subfont;
$header-color-breadcrumb-divider: darken($header-color-breadcrumb, 10%);

// Topbar colors
// ==================================================
$topbar-color-bg: $dark-blue;
$topbar-color-font: $white;
$topbar-color-shadow: $white;
$topbar-color-hamburgers: $font-color;
$topbar-color-bar-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);

// Sidebar colors
// ==================================================
$sidebar-color-bg: $white;
$sidebar-color-chevron: $font-light;
$sidebar-color-font: $font-light;
$sidebar-color-font--hover: darken($sidebar-color-font, 10%);
$sidebar-color-font--active: lighten($font-color, 10%);
$sidebar-color-font--active--hover: darken($sidebar-color-font--active, 15%);
$sidebar-color-icon: $accent;
$sidebar-color-icon--hover: darken($accent, 15%);
$sidebar-color-icon--active: darken($accent, 10%);
$sidebar-color-icon--active--hover: darken($accent, 20%);
$sidebar-color-item: $sidebar-color-bg;
$sidebar-color-item--active: $light-grey;
$sidebar-color-item-border: $accent;
$sidebar-color-submenu-icon: lighten($font-light, 15%);
$sidebar-color-submenu-icon--hover: lighten($font-light, 5%);
$sidebar-color-submenu-font: lighten($font-light, 5%);
$sidebar-color-submenu-font--hover: darken($sidebar-color-submenu-font, 10%);

// Tab Colors (PageTab)
// ==================================================
$pagetab-color-bg: $header-color-bg;
$pagetab-color-bg-xs: $white;
$pagetab-color-font: $light-blue;
$pagetab-color-font--hover: lighten($pagetab-color-font, 9%);
$pagetab-color-font--active: $white;
$pagetab-color-font-xs: $font-color;
$pagetab-color-font-xs--hover: lighten($pagetab-color-font-xs, 9%);
$pagetab-color-font-xs--active: $dark-blue;

// Color lists
// ==================================================
$colors: (
    red: $red,
    blue: $blue,
    dark: $dark,
    green: $green,
    white: $white,
    yellow: $yellow,
    limegreen: $limegreen
);

$role-colors: (
    superadmin: #d34848,
    finance_admin: #669b7c,
    hr_admin: #ffa952,
    marketer: #6a95fc,
    manager: #bf81ff,
    sales_executive: #f9989f,
    p_sales_executive: #c7b198,
    collection_executive: #915b4a,
    dbs_executive: #abdb33
);

$social-colors: (
    facebook: #3b5998,
    instagram: #e4405f,
    linkedin: #007bb6,
    github: #222,
    youtube: #cd201f,
    twitter: #00aced
);

$label-opacity: (
    red: 35%,
    blue: 25%,
    dark: 50%,
    green: 37%,
    white: 10%,
    yellow: 25%,
    limegreen: 25%
);
