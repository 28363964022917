@mixin get-gradient($background1, $background2, $opacity: 1, $gradient: 45deg) {
    @include linear-gradient(to right, $background1 0%, $background2 100%);
}

@mixin linear-gradient($direction, $color-stops...) {
    @if is-direction($direction) == false {
        $color-stops: $direction, $color-stops;
        $direction: 180deg;
    }

    background: nth(nth($color-stops, 1), 1);
    background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
    background: linear-gradient($direction, $color-stops);
}

@function is-direction($value) {
    $is-keyword: index(
        (
            to top,
            to top right,
            to right top,
            to right,
            to bottom right,
            to right bottom,
            to bottom,
            to bottom left,
            to left bottom,
            to left,
            to left top,
            to top left
        ),
        $value
    );
    $is-angle: type-of($value) == "number" and index("deg" "grad" "turn" "rad", unit($value));

    @return $is-keyword or $is-angle;
}

@function legacy-direction($value) {
    @if is-direction($value) == false {
        @error "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.";
    }

    $conversion-map: (
        to top: bottom,
        to top right: bottom left,
        to right top: left bottom,
        to right: left,
        to bottom right: top left,
        to right bottom: left top,
        to bottom: top,
        to bottom left: top right,
        to left bottom: right top,
        to left: right,
        to left top: right bottom,
        to top left: bottom right
    );

    @if map-has-key($conversion-map, $value) {
        @return map-get($conversion-map, $value);
    }

    @return 90deg - $value;
}

@mixin breakpoint($map) {
    $query: "";
    @if map-has-key($map, min) {
        $query: append($query, "(min-width: #{map-get($map, min)})");
    }
    @if map-has-key($map, min) and map-has-key($map, max) {
        $query: append($query, "and");
    }
    @if map-has-key($map, max) {
        $query: append($query, "(max-width: #{map-get($map, max)})");
    }

    @media screen and #{$query} {
        @content;
    }
}

@mixin resize($dimensions) {
    @each $bp in $dimensions {
        @include breakpoint(map-get($breakpoints, $bp)) {
            @content;
        }
    }
}

@mixin xs() {
    @include breakpoint($xs) {
        @content;
    }
}

@mixin sm() {
    @include breakpoint($sm) {
        @content;
    }
}

@mixin md() {
    @include breakpoint($md) {
        @content;
    }
}

@mixin lg() {
    @include breakpoint($lg) {
        @content;
    }
}

@mixin sm-only() {
    @include breakpoint($sm-only) {
        @content;
    }
}

@mixin md-only() {
    @include breakpoint($md-only) {
        @content;
    }
}

@mixin ipad-v() {
    @include breakpoint($ipad-v, $ipad-h) {
        @content;
    }
}

@mixin ipad-h() {
    @include breakpoint($ipad-h, $ipad-v) {
        @content;
    }
}

@mixin get-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    -o-border-radius: $radius;
    border-radius: $radius;
}

@mixin get-positioned-radius($radius, $position) {
    -webkit-border-#{$position}-radius: $radius;
    -moz-border-#{$position}-radius: $radius;
    -ms-border-#{$position}-radius: $radius;
    -o-border-#{$position}-radius: $radius;
    border-#{$position}-radius: $radius;
}

@mixin get-transition($prop: 0.2s) {
    -webkit-transition: $prop;
    -moz-transition: $prop;
    -ms-transition: $prop;
    -o-transition: $prop;
    transition: $prop;
}

@mixin css-transform($prop) {
    -webkit-transform: $prop;
    -moz-transform: $prop;
    -ms-transform: $prop;
    -o-transform: $prop;
    transform: $prop;
}

@mixin get-shadow($value: $box-shadow) {
    -webkit-box-shadow: $value;
    -moz-box-shadow: $value;
    -ms-box-shadow: $value;
    -o-box-shadow: $value;
    box-shadow: $value;
}

/**
 * Facebook Shimmer Style
 * 
 * @author  Kian Lavi
 * @link    https://codepen.io/kianlavi/pen/BzbRZw
 * @since   0.1
 **/
@mixin shimmer($background, $shimmer, $duration: 1s) {
    background: $background;
    background-image: linear-gradient(
        to right,
        $background 0%,
        $shimmer 20%,
        $background 40%,
        $background 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 800px;
    display: inline-block;
    position: relative;

    animation-duration: $duration;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;

    -webkit-animation-duration: $duration;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;

    @keyframes placeholderShimmer {
        0% {
            background-position: -468px 0;
        }

        100% {
            background-position: 468px 0;
        }
    }
}

/**
 * 
 **/
@mixin blur($amount: 3px) {
    filter: blur($amount);
    -webkit-filter: blur($amount);
}

/**
 * In some cases, we need to hide the scrollbar, so we add a gradient to
 * the scrollable to indicate that it can be scrolled.
 * 
 * @author  Hash @ StackOverflow
 * @link    https://stackoverflow.com/a/44794221
 * @since   1.0
 **/
@mixin scrollable-gradient($hidingColor1: white 30%, $hidingColor2: rgba(255, 255, 255, 0)) {
    $background1: rgba(82, 101, 121, 0.2);
    $background2: rgba(82, 101, 121, 0);

    &.with-gradient {
        background: linear-gradient($hidingColor1, $hidingColor2),
            linear-gradient($hidingColor2, $hidingColor1) 0 100%,
            radial-gradient(farthest-side at 50% 0, $background1, $background2),
            radial-gradient(farthest-side at 50% 100%, $background1, $background2) 0 100%;
        background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
        background-repeat: no-repeat;
        background-attachment: local, local, scroll, scroll;
    }
}

@function aspectHeight($width, $height, $newWidth) {
    @return ($height/$width) * $newWidth;
}
