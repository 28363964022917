.aibim.member {
    z-index: 2;
    padding: 10px 0;
    position: relative;
    min-height: 300px;
    margin-top: -40px;
    background-color: $white;
    @include get-positioned-radius($std-radius, bottom-left);
    @include get-positioned-radius($std-radius, bottom-right);

    .logos {
        text-align: center;
        img {
            width: 150px;
            height: auto;
            margin: 0 3px;
            display: inline-block;
            @include xs() {
                width: 100px;
            }
        }
    }
}
